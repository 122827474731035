<script setup>
// import '@vuepic/vue-datepicker/dist/main.css';
import { format } from "date-fns";
import { defineProps, ref, watch, onMounted, computed } from 'vue';
// import { debounceFilter, watchWithFilter } from '@vueuse/core'
// import { IconAddressBook } from '@tabler/icons-vue'

const props = defineProps(['class'])

const expiredAt = ref();
const customNumberOfDay = ref(120);
const selectNumberDay = ref('custom');

const isCustomDay = ref(true);
const options = [
  { value: '5', label: '5 Days' },
  { value: '10', label: '10 Days' },
  { value: '15', label: '15 Days' },
  { value: '30', label: '30 Days' },
  { value: '60', label: '60 Days' },
  { value: 'custom', label: 'Custom days' },
  { value: '-', label: 'No Expiration Date' },
];

function addTodayByDays(days) {
  return new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
}

onMounted(() => {
  expiredAt.value = addTodayByDays(customNumberOfDay.value);
});

watch(selectNumberDay, (newVal) => {
  if (newVal === 'custom') {
    expiredAt.value = '';
    isCustomDay.value = true;
  } else if (newVal === '-') {
    expiredAt.value = '';
    isCustomDay.value = false;
  } else {
    expiredAt.value = addTodayByDays(newVal);
    isCustomDay.value = false;
  }
});



watch(customNumberOfDay, () => {
  expiredAt.value = addTodayByDays(customNumberOfDay.value);
});

const formattedExpiredAt = computed(() => {
  try {
    return format(new Date(expiredAt.value), 'dd-MM-yyyy');
  } catch (error) {
    return '';
  }
});
</script>

<template>
  <div :class="[
    props.class,
  ]">
    <h3 class="text-lg font-semibold">Expiration</h3>
    <p class="text-slate-500 text-sm">Recipients can no longer view or sign an envelope after it expires.</p>

    <div class="">
      <div class="flex gap-3">
        <label class="form-control w-full max-w-xs">
          <div class="label px-0">
            <span class="label-text">Days until envelopes expired</span>
          </div>
          <select class="select select-sm select-bordered max-w-xs" v-model="selectNumberDay">
            <option disabled selected>Select expiration date</option>
            <option v-for="option in options" :value="option.value">{{ option.label }}</option>
          </select>
        </label>
  
        <label v-if="isCustomDay" class="form-control w-1/3 max-w-xs">
          <div class="label px-0">
            <span class="label-text">Number of days</span>
          </div>
          <input
            v-model="customNumberOfDay"
            type="number"
            placeholder="Type number here"
            class="input input-sm input-bordered w-full max-w-xs"
          />
        </label>
      </div>

      <div class="label px-0">
        <input type="hidden" name="expired_at" :value="formattedExpiredAt">
        <p v-if="selectNumberDay !== '-'" class="label-text">
          Envelope will expire on:
          <span class="font-semibold">
            {{ formattedExpiredAt }}
          </span>
        </p>
        <p v-else class="label-text">
          Envelope will not expire
        </p>
      </div>
    </div>
  </div>
</template>
